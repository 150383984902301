import * as React from "react"
import PropTypes from "prop-types"
import video from "../images/HeaderVideo.mp4"
import crowshead from "../images/crowshead.png"

const Header = ({ siteTitle }) => {
  return (
    <div className="h-full">
      <header className="relative flex items-center justify-center mb-12 overflow-hidden h-screen-exc-header md:h-192">
        <div className="relative flex flex-col w-2/3 z-30 opacity-90">
          <img src={crowshead} className="w-4/5 md:w-1/5 m-auto" alt="Manchester Crows Team"></img>
          <h1 className="text-5xl md:text-8xl text-center text-white m-auto justify-center">{siteTitle}</h1>
          <h1 className="text-3xl md:text-5xl text-center text-crows-red m-auto justify-center">Flag Football</h1>
        </div>
        <div className="absolute z-20 w-full h-full bg-black opacity-25"></div>
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute z-10 w-auto min-w-full min-h-full max-w-none"
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </header>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
