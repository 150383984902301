import * as React from "react"
import crowshead from "../images/crowshead.png"
import { Bars3Icon } from "@heroicons/react/20/solid"

const navigation = [
  { name: 'Fixtures', href: '#fixtures' },
  { name: 'Socials', href: '#socials' },
  { name: 'What Is Flag Football?', href: '#about' },
  { name: 'Get In Touch', href: '#join' },
]

const TopBar = () => {

  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <header className="bg-crows-red text-white shadow-3xl sticky top-0 z-50">
      <nav className="mx-auto max-w-7xl px-6 lg:px-8  sm:block hidden" aria-label="Top">
        <div className="flex w-full items-center justify-between border-b border-white py-6 lg:border-none">
          <div className="flex items-center">
            <button>
              <span className="sr-only">Manchester Crows</span>
              <img className="h-10 object-cover" src={crowshead} alt="" />
            </button>
            <div className="ml-10 hidden space-x-8 lg:block">
              {navigation.map((link) => (
                <a key={link.name} href={link.href} className="text-base font-bold text-white hover:animate-pulse">
                  {link.name}
                </a>
              ))}
            </div>
          </div>
          <div className="ml-10 space-x-4">
            <a
              href="#join"
              className="inline-block font-heading rounded-md shadow-xl hover:shadow-inner bg-white ease-in py-2 px-4 text-xl font-medium text-crows-red hover:bg-crows-red hover:text-white"
            >
              Join Us
            </a>
          </div>
        </div>
        <div className="flex flex-wrap justify-center gap-x-6 py-4 lg:hidden">
          {navigation.map((link, i) => (
            <a key={i} href={link.href} className="text-base font-medium text-white hover:font-l">
              {link.name}
            </a>
          ))}
        </div>
      </nav>
      <nav className="py-2 lg:px-8 sm:hidden block overflow-hidden" aria-label="Top">
        <Bars3Icon className=" text-white h-10 w-10 ml-auto mr-3" onClick={() => setIsOpen(!isOpen)}/>
        {isOpen ? (
          <div className="flex flex-col w-screen h-screen items-center justify-center">

              <img src={crowshead} className=" h-32 mb-10 object-cover" alt="" />

            {navigation.map((link, i) => (
              <a key={i} href={link.href} className="text-3xl w-full flex justify-center py-8 font-medium text-white border-t border-gray-300 font-heading" onClick={() => setIsOpen(!isOpen)}>
                {link.name}
              </a>
            ))}
          </div>
        ) : null}
      </nav>
    </header>
  )
}

export default TopBar